<template>
  <div class="resseguro">
    <h4 class="mb-10">Resseguro</h4>

    <div class="mb-10 w-2/12">
      <p>
        <b>Tipo de resseguro</b>
      </p>

      <el-select
        v-model="tipoResseguro"
        placeholder="Selecione"
        class="resseguro-mapfre-select"
        @change="trocarTipoResseguro"
      >
        <el-option
          v-for="resseguro in tiposResseguros"
          :key="resseguro.Id"
          :label="resseguro.Nome"
          :value="resseguro.Id"
        >
        </el-option>
      </el-select>
    </div>

    <div class="box-resseguro" v-if="tipoResseguro !== 1">
      <div
        class="px-8"
        v-for="(resseguradora, index) in listaResseguradoras"
        :key="index"
      >
        <p class="mb-1">
          <b>Resseguradora</b>
        </p>

        <div class="vx-row mb-0 mt-0 box-resseguro-resseguradora">
          <div class="vx-col w-2/12">
            <p>
              <b>CNPJ</b>
            </p>
            <vs-input
              v-mask="['##.###.###/####-##']"
              class="w-full"
              name="cnpj"
              size="large"
              v-model="(resseguradora || {}).Reinsurer.Person.CpfCnpj"
              @blur="buscarResseguradora($event, index)"
            />
          </div>

          <div class="vx-col w-3/12">
            <p>
              <b>Razão social</b>
            </p>
            <vs-input
              class="w-full"
              name="nome"
              size="large"
              v-model="(resseguradora || {}).Reinsurer.Person.Name"
              @blur="enviarInformacoes()"
            />
          </div>

          <div class="vx-col w-2/12">
            <p>
              <b>% participação do contrato</b>
            </p>
            <vs-input
              v-mask="['###.##', '##.##', '#.##']"
              class="w-full"
              name="participacaoContrato"
              size="large"
              :value="formatPercent((resseguradora || {}).Percent)"
              @input="updatePercent($event, index)"
              @blur="enviarInformacoes()"
            />
          </div>

          <div class="vx-col w-2/12">
            <p>
              <b>Número slip</b>
            </p>
            <vs-input
              v-mask="['#########']"
              class="w-full"
              name="numeroSlip"
              size="large"
              v-model="(resseguradora || {}).NumberSlip"
              @blur="enviarInformacoes()"
            />
          </div>

          <div class="vx-col w-2/12">
            <p>
              <b>Ano do contrato</b>
            </p>
            <vs-input
              v-mask="['####']"
              class="w-full"
              name="anoContrato"
              size="large"
              v-model="(resseguradora || {}).ContractYear"
              @blur="enviarInformacoes()"
            />
          </div>

          <div
            class="trash-icon-mapfre w-1/12"
            @click="removerResseguradora(index)"
          >
            <i v-if="index !== 0" class="onpoint-trash icon-font"></i>
          </div>
        </div>
      </div>
      <div class="footer-right">
        <div class="plus_button" @click="adcResseguradora">
          <span class="adicionar-resseguradora">Adicionar resseguradora</span>
          <i class="onpoint-plus icon-font"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import * as Utils from "@/utils/utils";
import axiosInstance from "@/axios";
export default {
  name: "resseguro-mapfre",
  directives: { mask },
  components: {
    mask
  },
  data() {
    return {
      tiposResseguros: [
        {
          Nome: "1 - Contrato Automático",
          Id: 1
        },
        {
          Nome: "2 - Facultativo",
          Id: 2
        }
      ],
      tipoResseguro: null,
      listaResseguradoras: [],
      Reinsurance: {
        ReinsuranceType: 2,
        Reinsurers: []
      },
      resseguradoraMock: {
        Id: "",
        Percent: "",
        NumberSlip: "",
        ContractYear: "",
        ProposalId: "",
        CompanyId: "",
        Reinsurer: {
          PersonId: "",
          Person: {
            CpfCnpj: "",
            Name: ""
          }
        }
      }
    };
  },
  computed: {
    ...mapGetters("proposta-module", ["proposta"])
  },
  watch: {
    proposta() {
      this.init()
    }
  },
  methods: {
    adcResseguradora() {
      this.resetResseguradoraMock();
      this.listaResseguradoras.push(Utils.deepClone(this.resseguradoraMock));
    },
    removerResseguradora(index) {
      this.listaResseguradoras.splice(index, 1);
    },
    async buscarResseguradora(event, index) {
      if (!Utils.isValidCnpj(event.target.value)) {
        this.$onpoint.errorModal("O CNPJ informado não é válido!");
        return;
      }
      if (this.verificarResseguradoraDuplicada(event.target.value)) {
        this.$onpoint.errorModal(
          "Resseguradora já se encontrada cadastrada nesta proposta!"
        );
        this.listaResseguradoras[index].Reinsurer.Person.CpfCnpj = "";
        return;
      }
      let cnpj = this.removerMascaraCpfCnpj(event.target.value);
      await this.$onpoint.loading(async () => {
        return await axiosInstance
          .get(`api/Reinsurer/GetSearchReinsurers?searchValue=${cnpj}`)
          .then(response => response.data.Response)
          .then(response => {
            this.listaResseguradoras[index].Reinsurer.Person.Name = response.Data ? response.Data[0].Name : "";
            this.listaResseguradoras[index].Id = response.Data ? response.Data[0].Id : "";
            this.listaResseguradoras[index].Reinsurer.PersonId = response.Data ? response.Data[0].PersonId : "";
            this.enviarInformacoes()
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex.response.data.Errors);
          });
      });
    },
    resetResseguradoraMock() {
      Utils.clearObjectAttributes(this.resseguradoraMock)
      this.resseguradoraMock.ProposalId = this.proposta.Id
      this.resseguradoraMock.CompanyId = this.proposta.CompanyId
    },
    trocarTipoResseguro() {
      if (this.tipoResseguro == 1) {
        this.Reinsurance = null;
        this.listaResseguradoras = []
        this.resetResseguradoraMock();
      }
      if (this.tipoResseguro == 2) {
        this.Reinsurance = {}
        this.Reinsurance.ReinsuranceType = 2;
        this.Reinsurance.Reinsurers = [];
        if(this.listaResseguradoras.length === 0 ) this.adcResseguradora()
      }

      this.$emit("tipoResseguroMapfre", this.tipoResseguro);
      this.enviarInformacoes()
    },
    lidarComResseguradora() {
      if (this.tipoResseguro == 1) {
        this.Reinsurance = null;
      }
      if (this.tipoResseguro == 2) {
        this.Reinsurance.ReinsuranceType = 2;
      }
    },
    removerMascaraCpfCnpj(cpfCnpj) {
      return Utils.removeSpecialChars(cpfCnpj);
    },
    verificarResseguradoraDuplicada() {
      const cnpjsVistos = new Set();
      for (const resseguradora of this.listaResseguradoras) {
        const cnpj = resseguradora.Reinsurer.Person.CpfCnpj;
        if (cnpjsVistos.has(cnpj)) {
          return true;
        }
        cnpjsVistos.add(cnpj);
      }
      return false;
    },
    formatPercent(value) {
      if (typeof value === 'number') {
        return value.toFixed(2).toString();
      }
      return '';
    },
    updatePercent(value, index) {
      const percent = parseFloat(value);
      if (!isNaN(percent)) {
        this.listaResseguradoras[index].Percent = percent;
      }
    },
    enviarInformacoes() {
      if (this.tipoResseguro === 2) this.Reinsurance.Reinsurers = this.listaResseguradoras;
      this.$emit("resseguroMapfre", this.Reinsurance);
    },
    init() {
      this.tipoResseguro = !this.proposta.HasReinsurers ? 1 : 2;
      this.listaResseguradoras = Utils.deepClone(this.proposta.Reinsurers);
      if (this.listaResseguradoras.length == 0) this.adcResseguradora()
      this.lidarComResseguradora();
      this.enviarInformacoes()
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss">
.box-resseguro {
  background: #f8f8f8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 20px 0px;
  padding: 10px;
  &-resseguradora {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.footer-right {
  margin: 20px 0;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}
.trash-icon-mapfre {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  color: rgba(var(--vs-primary), 1) !important;
  position: relative;
}
.adicionar-resseguradora {
  font-style: normal;
  font-weight: 600;
  font-size: 16.8427px;
  line-height: 21px;
  text-align: right;
  color: rgba(var(--vs-primary), 1) !important;
  margin: 0 15px;
}
.plus_button {
  cursor: pointer;
  i {
    background: rgba(var(--vs-primary), 1) !important;
    color: #fff;
    border-radius: 50%;
    padding: 5px;
  }
}
</style>

<style lang="scss" scoped>
.icon-font::before {
  font-size: 16px !important;
  margin: 10px;
  position: relative;
  right: 1px;
  top: 1px;
}
</style>