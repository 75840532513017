var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cosseguro" }, [
    _c("h4", { staticClass: "mb-10" }, [_vm._v("Cosseguro")]),
    _c(
      "div",
      { staticClass: "mb-10 w-2/12" },
      [
        _vm._m(0),
        _c(
          "el-select",
          {
            staticClass: "cosseguro-mapfre-select",
            attrs: { placeholder: "Selecione" },
            on: { change: _vm.trocartipoCosseguro },
            model: {
              value: _vm.tipoCosseguro,
              callback: function($$v) {
                _vm.tipoCosseguro = $$v
              },
              expression: "tipoCosseguro"
            }
          },
          _vm._l(_vm.tiposCosseguro, function(cosseguro) {
            return _c("el-option", {
              key: cosseguro.Id,
              attrs: { label: cosseguro.Nome, value: cosseguro.Id }
            })
          }),
          1
        )
      ],
      1
    ),
    _vm.tipoCosseguro === 1 || _vm.tipoCosseguro === 2
      ? _c(
          "div",
          { staticClass: "box-cosseguro" },
          [
            _vm._l(_vm.listaCosseguradoras, function(cosseguradora, idx) {
              return _c("div", { key: idx, staticClass: "px-8" }, [
                _vm._m(1, true),
                _c("div", { staticClass: "box-cosseguro-cosseguradora" }, [
                  _c("div", { staticClass: "vx-col w-11/12" }, [
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "vx-col",
                          class: [_vm.tipoCosseguro === 1 ? "w-4/12" : "w-3/12"]
                        },
                        [
                          _vm._m(2, true),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "vuemask",
                                rawName: "v-vuemask",
                                value: ["##.###.###/####-##"],
                                expression: "['##.###.###/####-##']"
                              }
                            ],
                            staticClass: "w-full",
                            attrs: { name: "cnpj", size: "large" },
                            model: {
                              value: _vm.teste,
                              callback: function($$v) {
                                _vm.teste = $$v
                              },
                              expression: "teste"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "vx-col",
                          class: [_vm.tipoCosseguro === 1 ? "w-4/12" : "w-3/12"]
                        },
                        [
                          _vm._m(3, true),
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: { name: "nome", size: "large" },
                            model: {
                              value: _vm.teste,
                              callback: function($$v) {
                                _vm.teste = $$v
                              },
                              expression: "teste"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "vx-col",
                          class: [_vm.tipoCosseguro === 1 ? "w-4/12" : "w-3/12"]
                        },
                        [
                          _c("p", [
                            _c("b", [
                              _vm._v(
                                "Código da cosseguradora " +
                                  _vm._s(_vm.tipoCosseguro === 2 ? "líder" : "")
                              )
                            ])
                          ]),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "vuemask",
                                rawName: "v-vuemask",
                                value: ["###.##", "##.##", "#.##"],
                                expression: "['###.##', '##.##', '#.##']"
                              }
                            ],
                            staticClass: "w-full",
                            attrs: {
                              name: "participacaoContrato",
                              size: "large",
                              value: _vm.teste
                            }
                          })
                        ],
                        1
                      ),
                      _vm.tipoCosseguro === 2
                        ? _c(
                            "div",
                            { staticClass: "vx-col w-3/12" },
                            [
                              _vm._m(4, true),
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "vuemask",
                                    rawName: "v-vuemask",
                                    value: ["#########"],
                                    expression: "['#########']"
                                  }
                                ],
                                staticClass: "w-full",
                                attrs: { name: "numeroSlip", size: "large" },
                                model: {
                                  value: _vm.teste,
                                  callback: function($$v) {
                                    _vm.teste = $$v
                                  },
                                  expression: "teste"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]),
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "vx-col",
                          class: [_vm.tipoCosseguro === 1 ? "w-4/12" : "w-3/12"]
                        },
                        [
                          _vm._m(5, true),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "vuemask",
                                rawName: "v-vuemask",
                                value: ["##.###.###/####-##"],
                                expression: "['##.###.###/####-##']"
                              }
                            ],
                            staticClass: "w-full",
                            attrs: { name: "cnpj", size: "large" },
                            model: {
                              value: _vm.teste,
                              callback: function($$v) {
                                _vm.teste = $$v
                              },
                              expression: "teste"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "vx-col",
                          class: [_vm.tipoCosseguro === 1 ? "w-4/12" : "w-3/12"]
                        },
                        [
                          _vm._m(6, true),
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: { name: "nome", size: "large" },
                            model: {
                              value: _vm.teste,
                              callback: function($$v) {
                                _vm.teste = $$v
                              },
                              expression: "teste"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "vx-col",
                          class: [_vm.tipoCosseguro === 1 ? "w-4/12" : "w-3/12"]
                        },
                        [
                          _vm._m(7, true),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "vuemask",
                                rawName: "v-vuemask",
                                value: ["###.##", "##.##", "#.##"],
                                expression: "['###.##', '##.##', '#.##']"
                              }
                            ],
                            staticClass: "w-full",
                            attrs: {
                              name: "participacaoContrato",
                              size: "large",
                              value: _vm.teste
                            }
                          })
                        ],
                        1
                      ),
                      _vm.tipoCosseguro === 2
                        ? _c(
                            "div",
                            { staticClass: "vx-col w-3/12" },
                            [
                              _vm._m(8, true),
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "vuemask",
                                    rawName: "v-vuemask",
                                    value: ["#########"],
                                    expression: "['#########']"
                                  }
                                ],
                                staticClass: "w-full",
                                attrs: { name: "numeroSlip", size: "large" },
                                model: {
                                  value: _vm.teste,
                                  callback: function($$v) {
                                    _vm.teste = $$v
                                  },
                                  expression: "teste"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]),
                    _vm.tipoCosseguro === 2
                      ? _c("div", { staticClass: "vx-row" }, [
                          _c(
                            "div",
                            { staticClass: "vx-col w-3/12" },
                            [
                              _vm._m(9, true),
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "vuemask",
                                    rawName: "v-vuemask",
                                    value: ["##.###.###/####-##"],
                                    expression: "['##.###.###/####-##']"
                                  }
                                ],
                                staticClass: "w-full",
                                attrs: { name: "cnpj", size: "large" },
                                model: {
                                  value: _vm.teste,
                                  callback: function($$v) {
                                    _vm.teste = $$v
                                  },
                                  expression: "teste"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "vx-col w-3/12" },
                            [
                              _vm._m(10, true),
                              _c("vs-input", {
                                staticClass: "w-full",
                                attrs: { name: "nome", size: "large" },
                                model: {
                                  value: _vm.teste,
                                  callback: function($$v) {
                                    _vm.teste = $$v
                                  },
                                  expression: "teste"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "trash-icon-mapfre",
                      on: {
                        click: function($event) {
                          return _vm.removerCosseguradora(idx)
                        }
                      }
                    },
                    [
                      _vm.tipoCosseguro === 1
                        ? _c("i", { staticClass: "onpoint-trash icon-font" })
                        : _vm._e()
                    ]
                  )
                ])
              ])
            }),
            _vm.tipoCosseguro === 1
              ? _c("div", { staticClass: "footer-right" }, [
                  _c(
                    "div",
                    {
                      staticClass: "plus_button",
                      on: { click: _vm.adcCosseguradora }
                    },
                    [
                      _c("span", { staticClass: "adicionar-cosseguradora" }, [
                        _vm._v("Adicionar cosseguradora")
                      ]),
                      _c("i", { staticClass: "onpoint-plus icon-font" })
                    ]
                  )
                ])
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("Tipo de cosseguro")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mb-1" }, [
      _c("b", [_vm._v("Cosseguradora")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("CNPJ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("Razão social")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("Identificação (opcional)")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("% de participação")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("% corretor")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("% gastos")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("Data da identificação (opcional)")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("Número apólice líder")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("Endosso")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }