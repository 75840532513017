<template>
  <div class="cosseguro">
    <h4 class="mb-10">Cosseguro</h4>

    <div class="mb-10 w-2/12">
      <p>
        <b>Tipo de cosseguro</b>
      </p>

      <el-select
        v-model="tipoCosseguro"
        placeholder="Selecione"
        class="cosseguro-mapfre-select"
        @change="trocartipoCosseguro"
      >
        <el-option
          v-for="cosseguro in tiposCosseguro"
          :key="cosseguro.Id"
          :label="cosseguro.Nome"
          :value="cosseguro.Id"
        >
        </el-option>
      </el-select>
    </div>

    <div
      class="box-cosseguro"
      v-if="tipoCosseguro === 1 || tipoCosseguro === 2"
    >
      <div
        class="px-8"
        v-for="(cosseguradora, idx) in listaCosseguradoras"
        :key="idx"
      >
        <p class="mb-1">
          <b>Cosseguradora</b>
        </p>

        <div class="box-cosseguro-cosseguradora">
          <div class="vx-col w-11/12">
            <div class="vx-row">
              <div class="vx-col" :class="[tipoCosseguro === 1 ? 'w-4/12' : 'w-3/12']">
                <p>
                  <b>CNPJ</b>
                </p>
                <vs-input
                  v-vuemask="['##.###.###/####-##']"
                  class="w-full"
                  name="cnpj"
                  size="large"
                  v-model="teste"
                />
              </div>

              <div class="vx-col" :class="[tipoCosseguro === 1 ? 'w-4/12' : 'w-3/12']">
                <p>
                  <b>Razão social</b>
                </p>
                <vs-input
                  class="w-full"
                  name="nome"
                  size="large"
                  v-model="teste"
                />
              </div>

              <div class="vx-col" :class="[tipoCosseguro === 1 ? 'w-4/12' : 'w-3/12']">
                <p>
                  <b>Código da cosseguradora {{tipoCosseguro === 2 ? "líder" : ""}}</b>
                </p>
                <vs-input
                  v-vuemask="['###.##', '##.##', '#.##']"
                  class="w-full"
                  name="participacaoContrato"
                  size="large"
                  :value="teste"
                />
              </div>

              <div class="vx-col w-3/12" v-if="tipoCosseguro === 2">
                <p>
                  <b>Identificação (opcional)</b>
                </p>
                <vs-input
                  v-vuemask="['#########']"
                  class="w-full"
                  name="numeroSlip"
                  size="large"
                  v-model="teste"
                />
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col" :class="[tipoCosseguro === 1 ? 'w-4/12' : 'w-3/12']">
                <p>
                  <b>% de participação</b>
                </p>
                <vs-input
                  v-vuemask="['##.###.###/####-##']"
                  class="w-full"
                  name="cnpj"
                  size="large"
                  v-model="teste"
                />
              </div>

              <div class="vx-col" :class="[tipoCosseguro === 1 ? 'w-4/12' : 'w-3/12']">
                <p>
                  <b>% corretor</b>
                </p>
                <vs-input
                  class="w-full"
                  name="nome"
                  size="large"
                  v-model="teste"
                />
              </div>

              <div class="vx-col" :class="[tipoCosseguro === 1 ? 'w-4/12' : 'w-3/12']">
                <p>
                  <b>% gastos</b>
                </p>
                <vs-input
                  v-vuemask="['###.##', '##.##', '#.##']"
                  class="w-full"
                  name="participacaoContrato"
                  size="large"
                  :value="teste"
                />
              </div>

              <div class="vx-col w-3/12" v-if="tipoCosseguro === 2">
                <p>
                  <b>Data da identificação (opcional)</b>
                </p>
                <vs-input
                  v-vuemask="['#########']"
                  class="w-full"
                  name="numeroSlip"
                  size="large"
                  v-model="teste"
                />
              </div>
            </div>

            <div class="vx-row" v-if="tipoCosseguro === 2">
              <div class="vx-col w-3/12">
                <p>
                  <b>Número apólice líder</b>
                </p>
                <vs-input
                  v-vuemask="['##.###.###/####-##']"
                  class="w-full"
                  name="cnpj"
                  size="large"
                  v-model="teste"
                />
              </div>

              <div class="vx-col w-3/12">
                <p>
                  <b>Endosso</b>
                </p>
                <vs-input
                  class="w-full"
                  name="nome"
                  size="large"
                  v-model="teste"
                />
              </div>
            </div>
          </div>

          <div
            class="trash-icon-mapfre"
            @click="removerCosseguradora(idx)"
          >
            <i v-if="tipoCosseguro === 1" class="onpoint-trash icon-font"></i>
          </div>
        </div>
      </div>
      <div class="footer-right" v-if="tipoCosseguro === 1">
        <div class="plus_button" @click="adcCosseguradora">
          <span class="adicionar-cosseguradora">Adicionar cosseguradora</span>
          <i class="onpoint-plus icon-font"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mask as vuemask } from "vue-the-mask";
import * as Utils from "@/utils/utils";
import axiosInstance from "@/axios";
export default {
  name: "cosseguro-mapfre",
  directives: { vuemask },
  data() {
    return {
      tiposCosseguro: [
        {
          Nome: "0 - Isento",
          Id: 0
        },
        {
          Nome: "1 - Cedido",
          Id: 1
        },
        {
          Nome: "2 - Aceito",
          Id: 2
        }
      ],
      tipoCosseguro: null,
      listaCosseguradoras: [],

      teste: ""
    };
  },
  computed: {
    ...mapGetters("proposta-module", ["proposta"])
  },
  watch: {
    proposta() {
      this.init();
    }
  },
  methods: {
    adcCosseguradora() {},

    removerCosseguradora(index) {},

    trocartipoCosseguro() {
      if (this.tipoCosseguro === 0) {
      } else if (this.tipoCosseguro == 1) {
      } else if (this.tipoCosseguro == 2) {
      }
    },

    init() {
      this.tipoCosseguro = this.proposta.CoInsurancers[0].CoInsuranceType;
      this.listaCosseguradoras = Utils.deepClone(this.proposta.CoInsurancers);

      console.log("init", this.proposta.CoInsurancers[0].CoInsuranceType);

      // if (this.listaResseguradoras.length == 0) this.adcCosseguradora();
      // this.lidarComResseguradora();
      // this.enviarInformacoes();
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.box-cosseguro {
  background: #f8f8f8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 20px 0px;
  padding: 10px;
  &-cosseguradora {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
.footer-right {
  margin: 20px 0;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}
.trash-icon-mapfre {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  color: rgba(var(--vs-primary), 1) !important;
  position: relative;
  margin-left: 2rem;
}
.adicionar-cosseguradora {
  font-style: normal;
  font-weight: 600;
  font-size: 16.8427px;
  line-height: 21px;
  text-align: right;
  color: rgba(var(--vs-primary), 1) !important;
  margin: 0 15px;
}
.plus_button {
  cursor: pointer;
  i {
    background: rgba(var(--vs-primary), 1) !important;
    color: #fff;
    border-radius: 50%;
    padding: 5px;
  }
}
</style>

<style lang="scss" scoped>
.icon-font::before {
  font-size: 16px !important;
  margin: 10px;
  position: relative;
  right: 1px;
  top: 1px;
}
</style>