import axiosInstance from "@/axios";

class MapfreProvider {
  async obterPermissoesModulo(modulo) {
    return await axiosInstance
      .get(`api/Permissao/BuscarPermissoes?Modulo=${modulo}`)
      .then(response => response.data.Response.Acao);
  }

  async buscarInformacoesCorretora(cnpj) {
    return await axiosInstance
      .get(`/api/Broker/ValidateBrokerRegistrationRequest?Cnpj=${cnpj}`)
      .then(response => response.data.Response)
      .then(response => {
        response.BankId = "";
        response.BankAccountTypeId = "";
        return response;
      });
  }

  async enviarEmailRecuperacaoSenha(data) {
    return await axiosInstance
      .post(`/api/Security/RequestRecoveryPasswordBroker`, data)
      .then(response => response.data.Response);
  }

  async listarTiposCanais(somenteCadastro){
    return await axiosInstance
    .get(`api/Broker/BuscarTipoCanal?SomenteCadastro=${somenteCadastro}`)
    .then(response => response.data.Response);
  }

  async listarBancos() {
    return await axiosInstance
    .get(`/api/Core/GetBanks`)
    .then(response => response.data.Response);
  }

  async listarTiposDeContas() {
    return await axiosInstance
    .get(`/api/Core/GetBankAccountTypes`)
    .then(response => response.data.Response);
  }

  async cadastrarNovaCorretora(dadosParaConcluirCadastro) {
    return await axiosInstance
    .post(`/api/Broker/InsertBroker`, dadosParaConcluirCadastro)
    .then(response => response.data.Response);
  }

  async exibirAgenciaParaCanalDaProposta() {
    return await axiosInstance
    .get(`api/Proposal/ObterPermissaoAgencia`)
    .then(response => response.data.Response);
  }

  async buscarTipoCanalPorCorretora() {
    return await axiosInstance
    .get(`api/Broker/BuscarTipoCanalPorCorretora`)
    .then(response => response.data.Response);
  }
}

export default new MapfreProvider();
