import CoreProvider from "../../providers/core-provider";
import ProposalProvider from "../../providers/proposal-provider";
export default {
  data() {
    return {
      contractualTerms: [],
      particularClausesEdit: false,
      particularClauses: [],
      particularClausesSelect: [],
      particularClausesObjectValue: null,
      particularClausesChange: null,
      particularClausesDialog: false
    };
  },
  computed: {

    tagValues() {
      if (!this.contractualTerms || this.contractualTerms.length == 0)
        return [];

      return this.contractualTerms.map(ct => {
        let JsonTag = ct.ContractualTerm.JSONTag;
        let JsonTagValue = ct.JSONTagValue;
        let Value = ct.Value;
        let Type = ct.Type;
        let ContractualTermId = ct.ContractualTerm.Id;
        return { JsonTag, JsonTagValue, Value, Type, ContractualTermId };
      });
    }
  },
  watch: {},
  methods: {

    async updateParticularClauses(proposeId) {
      await this.$onpoint.loading(async() => {
        try {
          const res = await ProposalProvider.updateParticularClauses(
            proposeId,
            (this.particularClausesChange || []).map(x => { return {...x, proposalId: proposeId } })
          );
          if (res.status == 200) {
            this.particularClausesDialog = false;
          }
        } catch (error) {
          this.$onpoint.errorModal(
            error.response.Data ? error.response.Data.Errors : error.message
          );
        }
      });
    },

    proposalTermsValuesChange(values) {
      if (!values || values.length == 0) return;
      this.particularClausesChange = values;
      this.particularClausesObjectValue = "";
      values.forEach(value => {
        this.particularClausesObjectValue += `${value.Value}`;
      });
    },

    setParticularClausesSelectItems() {
      this.particularClausesSelect = [];

      if (!this.contractualTerms || this.contractualTerms.length == 0) return;

      this.particularClauses.forEach(particularClause => {
        let exists =
          this.contractualTerms.find(
            contractualTerm =>
            contractualTerm.ContractualTermId ==
            particularClause.ContractualTermId
          ) != null;

        if (exists) this.particularClausesSelect.push(particularClause);
      });
    },

    async getParticularClauses(modalityId, proposeUniqueId) {
      if (!modalityId) return;

      await this.$onpoint.loading(async() => {
        try {
          const res = await CoreProvider.getParticularClauses(modalityId);
          if (res.status == 200) this.particularClauses = res.data.Response;

          if (proposeUniqueId) {
            await this.getContractualTerms(proposeUniqueId)
            this.setParticularClausesSelectItems();
          }
        } catch (error) {
          this.$onpoint.errorModal(
            error.response.Data ? error.response.Data.Errors : error.message
          );
        }
      });
    },

    async getContractualTerms(proposeUniqueId) {
      await this.$onpoint.loading(async() => {
        try {
          const res = await ProposalProvider.getContractualTerms(
            proposeUniqueId
          );
          if (res.status == 200) {
            this.contractualTerms = res.data.Response;
          }
        } catch (error) {
          this.$onpoint.errorModal(
            error.response.Data ? error.response.Data.Errors : error.message
          );
        }
      });
    }
  }
};
