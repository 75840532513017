<template>
  <section class="proposal-documents">
    <h4 class="mt-10">Documentação do corretor</h4>
    <div class="vx-row">
      <div class="vx-col pt-5 text-right w-full mt-2">
        <vs-button @click.native="openDialogUpload()" color="primary">
          <span class="font-semibold pr-2 pl-2">
            Upload de documento
          </span>
        </vs-button>
      </div>
    </div>
    <vs-table
      class="mt-10 table-available"
      :data="validDocuments"
      noDataText="Nenhum arquivo foi cadastrado"
    >
      <template slot="thead">
        <vs-th>
          Nome
        </vs-th>
        <vs-th>
          Origem
        </vs-th>
        <vs-th>
          Data de inserção
        </vs-th>
        <vs-th>
          Ações
        </vs-th>
      </template>
      <template>
        <vs-tr
          :data="document"
          :key="indextr"
          v-for="(document, indextr) in validDocuments"
        >
          <vs-td>{{ validDocuments[indextr].Name }}</vs-td>
          <vs-td>{{
            (validDocuments[indextr].CreateByPerson || {}).Name
          }}</vs-td>
          <vs-td>{{
            validDocuments[indextr].CreateDate | moment("DD/MM/YYYY HH:mm")
          }}</vs-td>
          <vs-td>
            <vs-button
              class="p-0"
              color="dark"
              type="flat"
              title="Download do documento"
              @click.native="downloadDocument(validDocuments[indextr].UniqueId)"
            >
              <i class="onpoint-download-simple icon-font"></i>
            </vs-button>
            <vs-button
              class="p-0"
              color="dark"
              type="flat"
              title="Editar nome do documento"
              @click.native="
                openDialogEditDocument(validDocuments[indextr].UniqueId)
              "
            >
              <i class="onpoint-pencil-simple-line icon-font"></i>
            </vs-button>
            <vs-button
              class="p-0"
              color="dark"
              type="flat"
              title="Excluir documento"
              @click.native="
                openDialogDeleteDocument(
                  validDocuments[indextr].UniqueId,
                  validDocuments[indextr].Name
                )
              "
            >
              <i class="onpoint-trash icon-font"></i>
            </vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div class="download-all" @click="downloadAllDocuments()">
      <span>Baixar todos</span><i class="onpoint-download icon-font"></i>
    </div>

    <h4>Documentos excluidos</h4>
    <vs-table
      class="mt-10 table-trash"
      :data="trashDocuments"
      noDataText="Nenhum arquivo foi excluído"
    >
      <template slot="thead">
        <vs-th>
          Nome
        </vs-th>
        <vs-th>
          Origem
        </vs-th>
        <vs-th>
          Data de inserção
        </vs-th>
        <vs-th>
          Excluido por
        </vs-th>
        <vs-th>
          Data da exclusão
        </vs-th>
        <vs-th>
          Ações
        </vs-th>
      </template>
      <template>
        <vs-tr
          :data="document"
          :key="indextr"
          v-for="(document, indextr) in trashDocuments"
        >
          <vs-td>{{ trashDocuments[indextr].Name }}</vs-td>
          <vs-td>{{
            (trashDocuments[indextr].CreateByPerson || {}).Name
          }}</vs-td>
          <vs-td>{{
            trashDocuments[indextr].CreateDate
              ? $utils.dateToDDMMYYYY(trashDocuments[indextr].CreateDate)
              : "-"
          }}</vs-td>
          <vs-td>{{
            (trashDocuments[indextr].UpdateByPerson || {}).Name
          }}</vs-td>
          <vs-td>{{
            trashDocuments[indextr].CreateDate | moment("DD/MM/YYYY HH:mm")
          }}</vs-td>
          <vs-td
            ><p
              class="restore"
              @click="restoreDocument(trashDocuments[indextr].UniqueId)"
            >
              Restaurar
            </p></vs-td
          >
        </vs-tr>
      </template>
    </vs-table>
    <vs-popup
      class="popup-upload-document z-index-modal"
      title="Upload dedocumento"
      :active.sync="openDialogAddDocumente"
    >
    <p v-if="files.name"><b> Arquivo Selecionado:</b> {{ files.name }}</p>
      <div class="vx-row flex justify-center">

        <div class="upload-container">
          <div class="drag-drop-file">
            <label class="drag-drop-file-text-content" for="upload">
              <i style="font-size: 18px" class="onpoint-file icon-font"></i>
              <p class="text">Selecionar Arquivo</p>
            </label>
            <input
              id="upload"
              type="file"
              name="avatar"
              @change="setValue($event.target.files[0])"
            />
          </div>
          <az-upload
            style="display:none"
            :singleUpload="true"
            :controller="uploadDocumentController"
          />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="flex font-semibold">Nomear documento:</p>
          <vs-input class="w-full" v-model="documentName" size="large" />
        </div>
      </div>
      <div class="vx-row flex justify-end">
        <div>
          <vs-button
            @click.native="uploadDocument(documentName)"
            color="primary"
          >
            <span class="font-semibold pr-2 pl-2">
              Enviar
            </span>
          </vs-button>
        </div>
      </div>
    </vs-popup>
    <vs-popup
      class="popup-edit-documents z-index-modal"
      title="Editar nome"
      :active.sync="openEditDocument"
    >
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="flex font-semibold">Informe o novo nome</p>
          <vs-input class="w-full" v-model="newDocumentName" size="large" />
        </div>
      </div>
      <div class="vx-row flex justify-end">
        <div>
          <vs-button @click.native="editDocument()" color="primary">
            <span class="font-semibold pr-2 pl-2">
              Confirmar
            </span>
          </vs-button>
        </div>
      </div>
    </vs-popup>
    <vs-popup
      class="popup-delete-documents z-index-modal"
      title="Deletar arquivo"
      :active.sync="openDeleteDocument"
    >
      <div class="vx-row flex justify-center">
        <div>
          <h4>Confirma a ação de deleter o documento?</h4>
        </div>
      </div>
      <div class="vx-row flex justify-center">
        <div class="document-name">{{ documentName }}</div>
      </div>
      <div class="vx-row flex justify-center">
        <div>
          <vs-button
            color="dark"
            class="mr-10"
            @click.native="openDeleteDocument = false"
          >
            <span class="font-semibold pr-2 pl-2">
              Voltar
            </span>
          </vs-button>
          <vs-button
            @click.native="deleteDocument(RequestDocumentUniqueId)"
            color="dark"
          >
            <span class="font-semibold pr-2 pl-2">
              Confirmar
            </span>
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </section>
</template>
<script>
import { mapActions } from "vuex";
import Upload from "@/components/az-upload/AzUpload.vue";
import { kanbanProvider } from "@/providers/kanban.provider";
export default {
  name: "proposal-documents",
  components: {
    Upload
  },

  props: ["proposalDocuments", "proposalDeletedDocuments", "documentType"],
  watch: {
    proposalDocuments(newVal) {
      this.validDocuments = newVal;
    },
    proposalDeletedDocuments(newVal) {
      this.trashDocuments = newVal;
    }
  },
  data() {
    return {
      files: [],
      openDialogAddDocumente: false,
      openEditDocument: false,
      openDeleteDocument: false,
      trashDocuments: [],
      validDocuments: [],
      documentName: "",
      newDocumentName: "",
      uploadDocumentController: {},
      RequestDocumentUniqueId: null,
      listChanged: false
    };
  },
  mounted() {
    this.$emit("loadProposta");
  },
  methods: {
    ...mapActions("proposta-module", [
      "uploadDocumentoByProposal",
      "deleteDocumentProposal",
      "getDocumentoPorId",
      "editNameDocumentProposal",
      "restoreDocumentProposal",
      "createDocumentProposal",
      "uploadDocumentProposal"
    ]),

    setValue(fileList) {
      this.files = fileList;
    },

    downloadDocument(RequestDocumentUniqueId) {
      this.$onpoint.loading(() => {
        return kanbanProvider
          .getRequestDocumentArchives(RequestDocumentUniqueId)
          .then(resp => {
            if (resp.length === 0)
              return this.$onpoint.errorModal("Nenhum anexo nesse documento!");
            resp.map(document => {
              kanbanProvider
                .getArchiveLinkToDownload(document.UniqueId)
                .then(data => {
                  window.open(data, "_blank");
                })
                .catch(ex => {
                  this.$onpoint.errorModal(ex);
                });
            });
          });
      });
    },
    editDocument() {
      this.$onpoint.loadingModal("Estamos alterando seu documento.", () => {
        return this.editNameDocumentProposal({
          RequestDocumentUniqueId: this.RequestDocumentUniqueId,
          ProposalUniqueId: this.$route.params.propostaUniqueId,
          Name: this.newDocumentName
        }).then(resp => {
          this.openEditDocument = false;
          this.$onpoint.successModal("Nome do documento alterado com sucesso!");
          this.$emit("loadProposta");
        });
      });
    },
    downloadAllDocuments() {
      this.validDocuments.map(document => {
        this.downloadDocument(document.UniqueId);
      });
    },
    async deleteDocument() {
      await this.$onpoint.loadingModal("Estamos deletando seu documento.", async () => {
        return await this.deleteDocumentProposal({
          ProposalUniqueId: this.$route.params.propostaUniqueId,
          RequestDocumentUniqueId: this.RequestDocumentUniqueId
        })
          .then(data => {
            this.openDeleteDocument = false;
            this.$onpoint.successModal("Operação realizada com sucesso!");
            this.$emit("loadProposta");
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    async uploadDocument(nameDocument) {
      if (!this.files || this.files.length == 0) {
        this.$onpoint.errorModal("Todos os campos são obrigatórios.");
        return;
      }

      await this.$onpoint.loadingModal("Estamos enviando seu documento.", async () => {
        return await this.uploadDocumentProposal(this.files)
          .then(async data => {
            return await this.createDocumentProposal({
              RequestDocument: nameDocument,
              ProposalUniqueId: this.$route.params.propostaUniqueId
            }).then(async response => {
              const dados = {
                ProposalUniqueId: this.$route.params.propostaUniqueId,
                RequestDocuments: [
                  {
                    RequestDocumentUniqueId: response.RequestDocument.UniqueId,
                    ArchivesUniqueId: [data.archives[0].UniqueId]
                  }
                ]
              };
              return await this.uploadDocumentoByProposal(dados).then(response => {
                this.$onpoint.successModal("Documento enviado com sucesso.");
                this.openDialogAddDocumente = false;
                this.files = [];
                this.$emit("loadProposta");
              });
            });
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },
    async restoreDocument(RequestDocumentUniqueId) {
      await this.$onpoint.loadingModal("Estamos restaurando seu documento.", async() => {
        return await this.restoreDocumentProposal({
          ProposalUniqueId: this.$route.params.propostaUniqueId,
          RequestDocumentUniqueId: RequestDocumentUniqueId
        })
          .then(data => {
            this.$onpoint.successModal("Operação realizada com sucesso!");
            this.$emit("loadProposta");
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },
    openDialogUpload() {
      if (this.uploadDocumentController.files().length)
        this.uploadDocumentController.remove();
      this.uploadDocumentController.reset();
      this.documentName = "";
      this.openDialogAddDocumente = true;
    },
    openDialogEditDocument(RequestDocumentUniqueId) {
      this.newDocumentName = "";
      this.openEditDocument = true;
      this.RequestDocumentUniqueId = RequestDocumentUniqueId;
    },
    openDialogDeleteDocument(RequestDocumentUniqueId, documentName) {
      this.documentName = this.openDeleteDocument = true;
      this.RequestDocumentUniqueId = RequestDocumentUniqueId;
      this.documentName = documentName;
    }
  }
};
</script>
<style lang="scss">
.table-available table thead tr {
  background: rgba(var(--vs-primary), 1);
  color: #fff;
}
.table-trash table thead tr {
  background: #e4e1e1;
  color: #636363;
}
.con-vs-popup .vs-popup {
  width: 500px;
  padding: 30px;
}
.document-name {
  background: #f8f8f8;
  padding: 20px 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: rgba(var(--vs-primary), 1);
}
.download-all {
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;
  color: rgba(var(--vs-primary), 1);
  span {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: rgba(var(--vs-primary), 1);
  }
}
.restore {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: rgba(var(--vs-primary), 1);
}
.z-index-modal {
  z-index: 52999;
}
</style>

<style lang="scss">
.upload-container {
  display: inline-flex;

  .drag-drop-file {
    width: 125px;
    height: 115px;

    /* grayscale/gray-4 */

    border: 1px dashed #8e8e8e;
    border-radius: 2px;
    cursor: pointer;

    input {
      width: 120px;
      height: 110px;
    }

    .drag-drop-file-text-content {
      position: absolute;
      width: 122px;
      height: 112px;
      background: white;
      display: block;
      padding: 28px 20px;
      cursor: pointer;

      i {
        justify-content: center;
        display: flex;
        font-size: 18px!important;
      }
      p {
        width: 100%;
        font-size: 14px;
        justify-content: center;
        display: flex;
        padding-top: 7px;
        text-align: justify;
        text-justify: inter-word;
      }
    }
  }

  .upload-file-content {
    width: 125px;
    height: 115px;
    margin-bottom: 20px;
    padding: 28px 20px;

    /* grayscale/gray-4 */

    border: 1px dashed #8e8e8e;
    border-radius: 2px;

    .remove-file {
      display: block;
      border: none;
      background: none;
      transform: translate(84px, -21px);
      color: #8e8e8e;
      position: absolute;
      border-radius: 50%;
      cursor: pointer;
      display: none;

      &:hover {
        color: #626262;
      }
    }

    &:hover {
      transform: translate(1px, -1px);
      box-shadow: -4px 4px 10px #8e8e8e5e;

      .remove-file {
        display: flex;
      }
    }

    .upload-file-text-content {
      height: 63px;
      background: white;
      display: block;
      border-radius: 6px;
      word-break: break-word;

      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

// .proposal-documents i{
//     font-size: 18px!important;
// }
</style>

<style lang="scss" scoped>
.icon-font::before {
  font-size: 16px !important;
  margin: 10px;
  position: relative;
  right: 1px;
  top: 1px;
}
</style>