var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col pt-5 w-full mt-2" },
        _vm._l(_vm.proposalLogs, function(regra, index) {
          return _c(
            "vs-collapse",
            { key: index, attrs: { type: "margin" } },
            [
              _c("vs-collapse-item", [
                _c(
                  "div",
                  { attrs: { slot: "header" }, slot: "header" },
                  [
                    _c("p", { staticClass: "text-sm font-semibold" }),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            (regra || {}).EngineExecutionType.Name ||
                              "Não informado"
                          ) +
                          "\n            "
                      )
                    ]),
                    _c("p", { staticClass: "text-sm" }, [
                      _c("b", [_vm._v("Executada em ")]),
                      _vm._v(
                        _vm._s(
                          _vm._f("moment")(
                            (regra || {}).CreateDate,
                            "DD/MM/YYYY - HH:mm"
                          )
                        ) + "\n            "
                      )
                    ]),
                    _c(
                      "vs-button",
                      {
                        staticClass: "status",
                        attrs: {
                          type: "filled",
                          size: "small",
                          color: _vm.getLabelColor(regra)
                        }
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              (regra || {}).RuleEngineResult.Success
                                ? "APROVADO"
                                : "REPROVADO"
                            ) +
                            "\n            "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "vx-row flex" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col flex-1" },
                    [
                      _c(
                        "vs-table",
                        {
                          attrs: {
                            data:
                              ((regra || {}).RuleEngineResult || {})
                                .RuleResults || [],
                            noDataText: "Nenhum histórico de regras executado."
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var data = ref.data
                                  return _vm._l(data, function(tr, indextr) {
                                    return _c(
                                      "vs-tr",
                                      { key: indextr },
                                      [
                                        _c(
                                          "vs-td",
                                          { attrs: { data: indextr } },
                                          [
                                            _c("div", { staticClass: "flex" }, [
                                              _c("span", [
                                                _vm._v(_vm._s(indextr + 1))
                                              ])
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data: data[indextr].RuleName
                                            }
                                          },
                                          [
                                            _c("div", { staticClass: "flex" }, [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(data[indextr].RuleName)
                                                )
                                              ])
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data: data[indextr].Success
                                            }
                                          },
                                          [
                                            _c("div", { staticClass: "flex" }, [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    data[indextr].Success
                                                      ? "Sim"
                                                      : "Não"
                                                  )
                                                )
                                              ])
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data: data[indextr].Messages
                                            }
                                          },
                                          [
                                            _c("div", { staticClass: "flex" }, [
                                              (data[indextr].Messages || [])
                                                .length == 0
                                                ? _c("span")
                                                : _vm._e(),
                                              (data[indextr].Messages || [])
                                                .length > 0
                                                ? _c("div", [
                                                    _c(
                                                      "ul",
                                                      _vm._l(
                                                        data[indextr]
                                                          .Messages || [],
                                                        function(
                                                          message,
                                                          indexMsg
                                                        ) {
                                                          return _c(
                                                            "li",
                                                            { key: indexMsg },
                                                            [
                                                              _vm._v(
                                                                "\n                              " +
                                                                  _vm._s(
                                                                    message
                                                                  ) +
                                                                  "\n                            "
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  ])
                                                : _vm._e()
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  })
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c(
                            "template",
                            { slot: "thead" },
                            [
                              _c("vs-th", [
                                _vm._v(
                                  "\n                    Etapa\n                  "
                                )
                              ]),
                              _c("vs-th", [
                                _vm._v(
                                  "\n                    Regra\n                  "
                                )
                              ]),
                              _c("vs-th", [
                                _vm._v(
                                  "\n                    Executada com sucesso?\n                  "
                                )
                              ]),
                              _c("vs-th", [
                                _vm._v(
                                  "\n                    Mensagem\n                  "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          )
        }),
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }